import { SET_CARD_NAME_CHECK_ERROR_MODAL, NameMatchActionTypes } from './name-match.actions';

interface NameMatchState {
  isCardNameCheckErrorModalOpen: boolean;
}

const initialState: NameMatchState = {
  isCardNameCheckErrorModalOpen: false,
};

const nameMatchReducer = (state = initialState, action: NameMatchActionTypes): NameMatchState => {
  switch (action.type) {
    case SET_CARD_NAME_CHECK_ERROR_MODAL:
      return { ...state, isCardNameCheckErrorModalOpen: action.payload };
    default:
      return state;
  }
};

export default nameMatchReducer;
