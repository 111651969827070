export const SET_CARD_NAME_CHECK_ERROR_MODAL = 'SET_CARD_NAME_CHECK_ERROR_MODAL';

export function setCardNameCheckErrorModal(isOpen: boolean) {
  return {
    type: SET_CARD_NAME_CHECK_ERROR_MODAL,
    payload: isOpen,
  } as const;
}

export type NameMatchActionTypes = ReturnType<typeof setCardNameCheckErrorModal>;
