import React from 'react';
import { useDispatch } from 'react-redux';
import { setCardNameCheckErrorModal } from '../../redux/name-match/name-match.actions';
import Button from '../Button';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import styled from 'styled-components';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const CardNameCheckErrorModal: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCardNameCheckErrorModal(false));
  };

  return (
    <Modal onDismiss={handleClose}>
      <Header3>Payment Blocked</Header3>
      <P1>We have detected that you are using a card that does not match the name you provided during registration.</P1>
      <P1>Please try to make payment again, ensuring that you are using your own card.</P1>
      <Action>
        <Button onClick={handleClose}>Close</Button>
      </Action>
    </Modal>
  );
};

export default CardNameCheckErrorModal;
